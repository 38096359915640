import { Box, Button, LinearProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { PlaylistUI, PlaylistItemUI } from '@seesignage/seesignage-utils'
import {
  deselectPlaylistItem,
  getPlaylist,
  reorderItems,
  selectPlaylistItem,
  updatePlaylistItems
} from '../../../actions/playlists'
import { getItemStyle } from '../../../components/Draggable/DraggableItems'
import {
  selectIsPlaylistSubmitting,
  selectPlaylistIsLoading,
  selectIsPlaylistModified,
  selectSelectedPlaylistItemIds,
  selectIsPlaylistMediaUploading,
  selectSelectedPlaylist
} from '../../../selectors/playlists'
import { StateInterface } from '../../../types/states'
import { ReorderItemsParams } from '../../../types/actions'
import { selectIsUserDeviceMobile, selectUser } from '../../../selectors/users'
import { selectVideosAreTranscoding } from '../../../selectors/media'
import { millisToMinutesAndSeconds } from '../../../utils/time'
import { calculateItemPercentage, getPlaylistDurationText } from '../../../utils/playlists'
import { selectDialogSize } from '../../../selectors/dialogs'
import Dialog from '../../Dialog'
import AddPlaylistItemWizard from '../Forms/AddPlaylistItemWizard/AddPlaylistItemWizard'
import { openDialog } from '../../../actions/dialogs'
import { AddButton } from '../../../components/Buttons'
import { validatePlaylistWriteAccess } from '../../../utils/permissions'
import { DialogSize } from '../../../types/dialogs'
import EditPlaylistToolbar from './EditPlaylistToolbar'
import PlaylistItemCard from './Items/PlaylistItemCard'

const useStyles = makeStyles(theme => ({
  items: {
    marginTop: 8,
    textAlign: 'center',
    minHeight: 600,
    marginBottom: 50
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: 4,
    display: 'flex'
  },
  emptyPlaylist: {
    marginTop: 8,
    textAlign: 'center'
  },
  droppableItem: {
    maxWidth: 600,
    margin: 'auto',
    padding: 12
  },
  buttonsContainer: {
    zIndex: 99,
    color: 'black',
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    position: 'sticky',
    top: 64,
    minHeight: 64,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.6)',
    [theme.breakpoints.down('md')]: {
      top: 54
    }
  },
  button: {
    margin: theme.spacing()
  },
  addButton: {
    marginRight: 15
  }
}))

interface StateProps {
  playlist?: PlaylistUI
  selectedPlaylistItemIds: string[]
  selectedPlaylistItems?: PlaylistItemUI[]
  isLoading: boolean
  unsavedChanges: boolean
  submitting: boolean
  isUploading: boolean
  videosTranscoding: boolean
  isMobile: boolean
  userHasWriteAccess: boolean
  dialogSize: DialogSize
}

interface DispatchProps {
  selectPlaylistItem: (id: string) => void
  deselectPlaylistItem: (id: string) => void
  reorderItems: (params: ReorderItemsParams) => void
  saveChanges: () => void
}

type EditPlaylistProps = StateProps & DispatchProps & WithTranslation

const EditPlaylist: React.FC<EditPlaylistProps> = ({
  playlist,
  selectPlaylistItem,
  deselectPlaylistItem,
  reorderItems,
  selectedPlaylistItems,
  selectedPlaylistItemIds,
  isLoading,
  unsavedChanges,
  saveChanges,
  submitting,
  isUploading,
  videosTranscoding,
  isMobile,
  dialogSize,
  userHasWriteAccess,
  t
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showPercentage, setShowPercentage] = useState(false)
  // reordering playlist items
  const onDragEndPlaylistItem = (result: DropResult) => {
    if (result.destination && result.destination.index !== result.source.index) {
      reorderItems({
        startIndex: result.source.index,
        endIndex: result.destination.index
      })
    }
  }

  if (!playlist || isLoading) {
    return <LinearProgress />
  }

  const { items, totalDuration, defaultInterval } = playlist

  const toolbarProps = {
    playlist,
    selectedPlaylistItems: selectedPlaylistItems || [],
    showPercentage,
    setShowPercentage
  }

  const buttonsDisabled =
    !unsavedChanges || submitting || isUploading || videosTranscoding || !userHasWriteAccess

  return (
    <Fragment>
      <EditPlaylistToolbar {...toolbarProps} />
      <Dialog
        dialogId='AddPlaylistItemWizard'
        title={t('playlists.editPlaylist.addItem')}
        noOpenDialogButton
        disableEnforceFocus
        buttonId='add-playlist-item'
        maxWidth={dialogSize.maxWidth}
        fullScreen={dialogSize.fullScreen}
        Content={<AddPlaylistItemWizard playlist={playlist} />}
      />
      {submitting && <LinearProgress />}
      <div className={classes.buttonsContainer}>
        {!isMobile && <Box sx={{ width: '33%' }}></Box>}
        <Box sx={{ display: 'flex', justifyContent: 'center', width: isMobile ? '90%' : '33%' }}>
          <Button
            className={classes.button}
            variant='contained'
            onClick={() => dispatch(getPlaylist(playlist.playlistId))}
            disabled={buttonsDisabled}
            id='cancel-playlist-items-button'>
            {t('general.cancel')}
          </Button>
          <Button
            className={classes.button}
            disabled={buttonsDisabled}
            variant='contained'
            color='primary'
            onClick={saveChanges}
            id='save-playlist-items-button'>
            {t('templates.forms.save')}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: isMobile ? '10%' : '33%' }}>
          <AddButton
            id='add-playlist-item'
            onClick={() => dispatch(openDialog('AddPlaylistItemWizard'))}
            className={classes.addButton}
            tooltipTitle={t('playlists.editPlaylist.addItem')}
            disabled={!userHasWriteAccess}
          />
        </Box>
      </div>
      <div className={classes.items} id='playlis-items-area'>
        {!userHasWriteAccess && (
          <Typography variant='body1' color='secondary'>
            {t('playlists.editPlaylist.noWritePermission')}
          </Typography>
        )}
        {totalDuration ? (
          <Fragment>
            <Typography variant='body1'>{getPlaylistDurationText(totalDuration)}</Typography>
            {totalDuration.max !== totalDuration.average && (
              <Typography variant='caption'>
                {t('playlists.durations.averageDuration', {
                  average: millisToMinutesAndSeconds(totalDuration.average)
                })}
              </Typography>
            )}
          </Fragment>
        ) : (
          <Typography variant='body1'>{t('playlists.durations.noDuration')}</Typography>
        )}
        {items.length ? (
          <DragDropContext onDragEnd={onDragEndPlaylistItem}>
            <Droppable droppableId='droppablePlaylist'>
              {({ innerRef, placeholder }) => (
                <div ref={innerRef} className={classes.droppableItem}>
                  {items.map((item, index) => {
                    const { itemId, isHidden } = item
                    const itemDuration = calculateItemPercentage(
                      item,
                      defaultInterval,
                      totalDuration
                    )
                    return (
                      <Draggable
                        key={itemId}
                        draggableId={itemId}
                        index={index}
                        isDragDisabled={!userHasWriteAccess}>
                        {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => {
                          const isItemSelected = selectedPlaylistItemIds.includes(itemId)
                          const playlistItemCardProps = {
                            item,
                            isItemSelected,
                            selectPlaylistItem,
                            deselectPlaylistItem,
                            itemDuration,
                            showPercentage: isHidden ? false : showPercentage,
                            isMobile,
                            userHasWriteAccess
                          }
                          return (
                            <div
                              ref={innerRef}
                              {...draggableProps}
                              {...dragHandleProps}
                              style={getItemStyle(
                                isDragging,
                                draggableProps.style,
                                isItemSelected,
                                isHidden
                              )}>
                              <PlaylistItemCard {...playlistItemCardProps} />
                            </div>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Typography className={classes.emptyPlaylist} variant='subtitle1' gutterBottom>
            {t('playlists.card.empty')}. {t('playlists.card.addNewItem')}.
          </Typography>
        )}
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const playlist = selectSelectedPlaylist(state)
  const selectedPlaylistItemIds = selectSelectedPlaylistItemIds(state)
  let selectedPlaylistItems
  if (playlist && playlist.items) {
    selectedPlaylistItems = playlist.items.filter(item =>
      selectedPlaylistItemIds.includes(item.itemId)
    )
  }
  const user = selectUser(state)
  return {
    playlist,
    selectedPlaylistItemIds,
    selectedPlaylistItems,
    isLoading: selectPlaylistIsLoading(state),
    unsavedChanges: selectIsPlaylistModified(state),
    submitting: selectIsPlaylistSubmitting(state),
    isUploading: selectIsPlaylistMediaUploading(state),
    videosTranscoding: selectVideosAreTranscoding(state),
    isMobile: selectIsUserDeviceMobile(state),
    dialogSize: selectDialogSize(state),
    userHasWriteAccess: playlist && user ? validatePlaylistWriteAccess(playlist, user) : false
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectPlaylistItem: (itemId: string) => dispatch(selectPlaylistItem(itemId)),
  deselectPlaylistItem: (itemId: string) => dispatch(deselectPlaylistItem(itemId)),
  reorderItems: (params: ReorderItemsParams) => dispatch(reorderItems(params)),
  saveChanges: () => dispatch(updatePlaylistItems())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditPlaylist))
